import React from "react";

import Logo_KJ from "../../../assets/images/logo/KJPromotors.png";
const KJPromotors = React.forwardRef((props, ref) => {
  return (
    <>
      <div className="row container align-items-center mx-0 mt-4" ref={ref}>
        <div className="col-md-8 text-justify">
          <h4 className="text-success  my-3 fw-bolder">KJ Promotors</h4>
          <p className="text-justify">
            KJ Promotors, a premier real estate and construction company
            dedicated to transforming your dreams into reality. With a strong
            focus on quality, innovation, and customer satisfaction, we strive
            to deliver exceptional properties that exceed expectations. As a
            trusted name in the industry, we bring together expertise in real
            estate development and construction, offering a comprehensive range
            of services to meet your needs.
          </p>
          <p className="text-justify">
            At KJ Promotors, we understand that your home is more than just a
            physical structure; it's a reflection of your aspirations and a
            place where memories are made. Our team of experienced
            professionals, including architects, engineers, project managers,
            and skilled craftsmen, work diligently to create living spaces that
            are thoughtfully designed, aesthetically pleasing, and built to
            stand the test of time.
          </p>
        </div>
        <div className="col-md-4">
          <img src={Logo_KJ} alt="jsacat" width="100%" className="rounded" />
        </div>
      </div>
    </>
  );
});

export default KJPromotors;
