import React from "react";
import Banner_JNCW_jpg_1 from "../../../assets/images/banner/jncw/jpg/banner-1.jpg";
import Banner_JNCW_jpg_2 from "../../../assets/images/banner/jncw/jpg/banner-2.jpg";
import Banner_JNCW_jpg_3 from "../../../assets/images/banner/jncw/jpg/banner-3.jpg";
import Banner_JNCW_jpg_4 from "../../../assets/images/banner/jncw/jpg/banner-4.jpg";
import Banner_JNCW_jpg_5 from "../../../assets/images/banner/jncw/jpg/banner-5.jpg";
import Banner_JNCW_jpg_6 from "../../../assets/images/banner/jncw/jpg/banner-6.jpg";
import Banner_JNCW_jpg_7 from "../../../assets/images/banner/jncw/jpg/banner-7.jpg";
import Banner_JNCW_jpg_8 from "../../../assets/images/banner/jncw/jpg/banner-8.jpg";

import Banner_JNCW_webp_1 from "../../../assets/images/banner/jncw/webp/banner-1.webp";
import Banner_JNCW_webp_2 from "../../../assets/images/banner/jncw/webp/banner-2.webp";
import Banner_JNCW_webp_3 from "../../../assets/images/banner/jncw/webp/banner-3.webp";
import Banner_JNCW_webp_4 from "../../../assets/images/banner/jncw/webp/banner-4.webp";
import Banner_JNCW_webp_5 from "../../../assets/images/banner/jncw/webp/banner-5.webp";
import Banner_JNCW_webp_6 from "../../../assets/images/banner/jncw/webp/banner-6.webp";
import Banner_JNCW_webp_7 from "../../../assets/images/banner/jncw/webp/banner-7.webp";
import Banner_JNCW_webp_8 from "../../../assets/images/banner/jncw/webp/banner-8.webp";

import BannerCarousel from "../../layouts/bannerCarousel";

const JNCW = () => {
  const JNCWBanner = [
    {
      jpg: Banner_JNCW_jpg_1,
      webp: Banner_JNCW_webp_1,
    },
    {
      jpg: Banner_JNCW_jpg_2,
      webp: Banner_JNCW_webp_2,
    },
    {
      jpg: Banner_JNCW_jpg_3,
      webp: Banner_JNCW_webp_3,
    },
    {
      jpg: Banner_JNCW_jpg_4,
      webp: Banner_JNCW_webp_4,
    },
    {
      jpg: Banner_JNCW_jpg_5,
      webp: Banner_JNCW_webp_5,
    },
    {
      jpg: Banner_JNCW_jpg_6,
      webp: Banner_JNCW_webp_6,
    },
    {
      jpg: Banner_JNCW_jpg_7,
      webp: Banner_JNCW_webp_7,
    },
    {
      jpg: Banner_JNCW_jpg_8,
      webp: Banner_JNCW_webp_8,
    },
  ];
  return (
    <>
      <BannerCarousel BannerContent={JNCWBanner} />
      <div className="container">
        <h4 className="my-5 fw-bolder text-header">
          Jawaharlal Nehru College for Women
        </h4>

        <div className="lh-lg">
          <p className="text-justify pt-3">
            Jawaharlal Nehru College for Women owned by management is in 8th
            year, by fruitful services in the cause of higher education in this
            part of this country. The college is located on the Trichy – Chennai
            National Highway- 45, Pali at Ulundurpet in Kallakurichi district.
            It has an extensive campus with all modern infrastructures in an
            extent of 15 acres. It is a self financing institution affiliated to
            Thriuvalluvar University at Vellore. The college offers
            undergraduate and postgraduate courses of Arts and Science streams.
            Academics are supplemented with co-curricular and extracurricular
            activities in which college has excellent track records.
          </p>

          <div className="mt-5">
            <h4 className="text-success  my-3 fw-bolder">Our Vision</h4>
            <ul className="">
              <li>
                To provide quality education that gives knowledge to serve the
                society. This education should be such that the student should
                be motivated to pursue higher studies. All such kinds of
                learning should be for the betterment of the students. Education
                is the only thing that makes the student a better humane in the
                society, who excels not only in academics but would understand
                the finer aspects of life. Such kind of teaching and learning
                community develops the education of rural students. Enrich to
                build confidence and enhance opportunities to succeed and should
                work together effectively to achieve the determined goals.
              </li>
            </ul>
          </div>
          <div className="mt-5">
            <h4 className="text-success  my-3 fw-bolder">Our Mission</h4>
            <ul className="">
              <li className="">
                Jawaharlal Nehru College for Women aims to develop every
                individual to meet global challenges. And we mould the students
                to go out as conscious citizens with concern. To provide skill
                based, technical, innovative educational environment for
                creating global competencies. Facilitate students to develop
                professional and life skills to enhance the employability.
                Bestow leadership qualities to brave new challenges. Practice
                the importance of human and ethical values.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-4">
          <a href="https://jncw.in" target="_blank" rel="noreferrer">
            <button className="btn btn-primary">Read More...</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default JNCW;
