import React from "react";
import Logo from "../../assets/images/logo/JSA_Groups_1.png";
const Footer = () => {
  return (
    <>
      <footer
        className="text-center text-lg-start text-dark mt-5"
        style={{ backgroundColor: "#ECEFF1" }}
      >
        <hr />

        <section className="">
          <div className="container text-center text-md-start mt-0">
            <div className="row  align-items-center">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <img
                  src={Logo}
                  alt="jsa - logo"
                  width="170"
                  className="d-block mx-auto"
                />
                <p className="text-justify mt-4">
                  Charitable Educational Trust to uplift the educational levels
                  of poor and to empower women through education in socio -
                  economically most backward district of TamilNadu
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Useful links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    background: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a href="/" className="text-dark">
                    Home
                  </a>
                </p>
                <p>
                  <a href="/about-us" className="text-dark">
                    About us
                  </a>
                </p>
                <p>
                  <a href="/group-of-institutions/jsacat" className="text-dark">
                    Our Institutions
                  </a>
                </p>
                <p>
                  <a href="/contact-us" className="text-dark">
                    Contact us
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    background: "#7c4dff",
                    height: "2px",
                  }}
                />
                <div className="row ">
                  <div className="col-md-1 p-0">
                    <i className="fa fa-home "></i>
                  </div>
                  <div className="col-md-11">
                    No.30/1, First floor , <br />
                    Jayam Arun Plaza , Fairlands , <br />
                    Advaitha Ashram Road,
                    <br />
                    Salem - 636 016.
                  </div>

                  <div className="col-md-1 p-0">
                    <i className="fa fa-envelope "></i>
                  </div>
                  <div className="col-md-11">
                    <p>svtrust.educ@gmail.com</p>
                  </div>
                  <div className="col-md-1 p-0">
                    <i className="fa fa-phone "></i>
                  </div>
                  <div className="col-md-11">
                    +91 73977 84467,
                    <br />
                    +91 95662 23456
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-1"
          style={{ background: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2023 Copyright :
          <span className="text-dark ms-2">Shri Venkateswara Trust</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
