import React from "react";
import Vision_img from "../../../assets/images/visionMission/visionMission.png";
import BannerCarousel from "../../layouts/bannerCarousel";

const OurAim = () => {
  return (
    <>
      <BannerCarousel BannerContent={null} />

      <div className="container">
        <h4 className="text-success text-center my-5 fw-bolder ">
          OUR AIMS & OBJECTIVES
        </h4>
        <div className="row align-items-center">
          <div className="col-md-9 text-justify">
            <ul className="lh-lg">
              <li>
                To establish, set up, promote, run, maintain, help
                charitable/educational Institutions and Human Resource
                Development Institutions for the welfare of all people
              </li>
              <li className="pt-4">
                To give world class education in Traditional SIDDHA Medicine
                originating in ancient Tamil Nadu in South India and to render
                dedicated service to mankind with humanity. Our research gives
                drive to communicate modern technology, biotechnology &
                Traditional Medicine into the characteristics of Research
                Oriented Teaching/Training, Diagnosis, Drug manufacturing &
                Treatment in systems of Indian medicine especially Siddha,
                Ayurvedha, and other related fields{" "}
              </li>
              <li className="pt-4">
                To seek knowledge and effect changes to scenario that can
                transform agriculture with an industry focus and commercialize
                this profession to sustain it for the beLerment of humanity at
                large. To extend outreach of agriculture education through
                national and international linkages{" "}
              </li>
              <li className="pt-4">
                To encourage Women Empowerment through education, training and
                employment{" "}
              </li>
              <li className="pt-4">
                To establish, promote, run, maintain Schools for Primary,
                Secondary, Sr. Secondary Education, Colleges for Engineering,
                Medical, Arts & Science, Polytechnics, Industrial/ Computer &
                Electronic Centres and Training institutions for Civils, UPSC,
                TNPSC, SSC, Banking, RRB, NEET, IIT JEE, CACPT, ICWA and Other
                entrance examinations
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <img src={Vision_img} alt="chairman" className="w-100 rounded" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurAim;
