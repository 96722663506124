import Iframe from "react-iframe";
import EnquiryForm from "./enquiryForm";
import BannerCarousel from "../../layouts/bannerCarousel";

const ContactUs = () => {
  return (
    <>
      <BannerCarousel BannerContent={null} />

      <section
        id="about"
        className="mt-4"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="page-header space-left bg-heading">
                <h5>Reach us </h5>
              </div>
              <div className="col-md-2 col-xs-2 mt-4">
                <i
                  className="fa fa-map-marker float-start fs-2"
                  aria-hidden="true"
                  // style="float: left; font-size: 26px; margin-right: 25px;"
                ></i>
              </div>
              <div className="col-md-10 col-xs-10 ms-5">
                <p>
                  No.30/1, First floor ,
                  <br /> Jayam Arun Plaza ,<br /> Fairlands ,<br />
                  Advaitha Ashram Road,
                  <br /> Salem - 636 016.
                </p>{" "}
              </div>

              <div className="col-md-2 col-xs-2">
                <i
                  className="fa fa-mobile float-start fs-2"
                  aria-hidden="true"
                  // style="float: left; font-size: 26px; margin-right: 25px;"
                ></i>
              </div>
              <div className="col-md-10 col-xs-10 ms-5">
                <p>
                  <a href="tel:+919566223456" className="text-decoration-none">
                    +91 9566 223 456
                  </a>
                </p>
                <p>
                  <a href="tel:+919361909073" className="text-decoration-none">
                    +91 9361 909 073
                  </a>
                </p>
              </div>
              <div className="col-md-2 col-xs-2">
                <i
                  className="fa fa-envelope-o float-start fs-3"
                  aria-hidden="true"
                  // style="float: left; font-size: 22px; margin-right: 25px;"
                ></i>
              </div>
              <div className="col-md-10 col-xs-10 ms-5">
                <p>
                  <a
                    href="mailto:arun.jsa11@gmail.com"
                    className="text-decoration-none"
                  >
                    arun.jsa11@gmail.com
                  </a>
                </p>
                <p>
                  <a
                    href="mailto:svtrust.educ@gmail.com"
                    className="text-decoration-none"
                  >
                    svtrust.educ@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="page-header space-left bg-heading">
                <h5>Locate us</h5>
              </div>
              <Iframe
                className=" mt-4  p-1  image-shadow"
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.2536406912736!2d78.14231389999999!3d11.6764282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf1ecd67f79cd%3A0xa75204ec1e331095!2sJSAGROUP!5e0!3m2!1sen!2sin!4v1690545222506!5m2!1sen!2sin"
                width="100%"
                height="320"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></Iframe>
            </div>
            <div className="page-header space-left mt-5  bg-heading">
              <h5>Enquiry Form</h5>
            </div>
            <div className="col-md-12 image-shadow p-4">
              {/* <?php include 'enquiry.php';?> */}
              <EnquiryForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
