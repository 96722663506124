import React from "react";
import Banner_JSACAT_jpg_1 from "../../../assets/images/banner/jsacat/jpg/banner-1.jpg";
import Banner_JSACAT_jpg_2 from "../../../assets/images/banner/jsacat/jpg/banner-2.jpg";
import Banner_JSACAT_jpg_3 from "../../../assets/images/banner/jsacat/jpg/banner-3.jpg";
import Banner_JSACAT_jpg_4 from "../../../assets/images/banner/jsacat/jpg/banner-4.jpg";
import Banner_JSACAT_jpg_5 from "../../../assets/images/banner/jsacat/jpg/banner-5.jpg";
import Banner_JSACAT_jpg_6 from "../../../assets/images/banner/jsacat/jpg/banner-6.jpg";
import Banner_JSACAT_jpg_7 from "../../../assets/images/banner/jsacat/jpg/banner-7.jpg";
import Banner_JSACAT_jpg_8 from "../../../assets/images/banner/jsacat/jpg/banner-8.jpg";

import Banner_JSACAT_webp_1 from "../../../assets/images/banner/jsacat/webp/banner-1.webp";
import Banner_JSACAT_webp_2 from "../../../assets/images/banner/jsacat/webp/banner-2.webp";
import Banner_JSACAT_webp_3 from "../../../assets/images/banner/jsacat/webp/banner-3.webp";
import Banner_JSACAT_webp_4 from "../../../assets/images/banner/jsacat/webp/banner-4.webp";
import Banner_JSACAT_webp_5 from "../../../assets/images/banner/jsacat/webp/banner-5.webp";
import Banner_JSACAT_webp_6 from "../../../assets/images/banner/jsacat/webp/banner-6.webp";
import Banner_JSACAT_webp_7 from "../../../assets/images/banner/jsacat/webp/banner-7.webp";
import Banner_JSACAT_webp_8 from "../../../assets/images/banner/jsacat/webp/banner-8.webp";

import BannerCarousel from "../../layouts/bannerCarousel";
import { Link } from "react-router-dom";

const JSACAT = () => {
  const JSACATBanner = [
    {
      jpg: Banner_JSACAT_jpg_1,
      webp: Banner_JSACAT_webp_1,
    },
    {
      jpg: Banner_JSACAT_jpg_2,
      webp: Banner_JSACAT_webp_2,
    },
    {
      jpg: Banner_JSACAT_jpg_3,
      webp: Banner_JSACAT_webp_3,
    },
    {
      jpg: Banner_JSACAT_jpg_4,
      webp: Banner_JSACAT_webp_4,
    },
    {
      jpg: Banner_JSACAT_jpg_5,
      webp: Banner_JSACAT_webp_5,
    },
    {
      jpg: Banner_JSACAT_jpg_6,
      webp: Banner_JSACAT_webp_6,
    },
    {
      jpg: Banner_JSACAT_jpg_7,
      webp: Banner_JSACAT_webp_7,
    },
    {
      jpg: Banner_JSACAT_jpg_8,
      webp: Banner_JSACAT_webp_8,
    },
  ];
  return (
    <>
      <BannerCarousel BannerContent={JSACATBanner} />
      <div className="container">
        <h4 className="my-5 fw-bolder text-header">
          JSA College of Agriculture and Technology
        </h4>

        <div className="lh-lg">
          <p className="text-justify pt-3">
            JSA College of Agriculture and Technology is one of Tamilnadu's
            leading agricultural colleges affiliated with Tamilnadu Agricultural
            University, Coimbatore and approved by the Tamilnadu government. It
            was established in the year 2015 by the Sri Venkateswara Educational
            Trust, Salem, which provides more than a decade of experience in
            educational field under the Chairmanship of Dr. K. Jayaraman,
            Distinguished Philanthropist and Chief Engineer (Rtd), Roads
            Department, Govt. Of Tamilnadu and Secretary Dr. J.S.A. Arun, JSA
            group of institutes.
          </p>
          <p className="text-justify pt-3 text-danger">
            The college, Located 6 km from Tholudhur (near Avatti) on the Trichy
            _ Chennai National High Way (NH _ 45) . Chennai National High Way
            (NH _ 45)
          </p>
          <p className="text-justify pt-3">
            The campus provides colossal opportunities in placing students in
            the best companies around Tamil Nadu and endows an extensive
            cognitive and creative knowledge to the students. We provide quality
            education in the agriculture domain by incorporating the latest
            technologies and traditional methodologies whereby students can
            develop their knowledge with up-to-date techniques and work
            independently in the future.Agriculture and agriculture-related
            businesses and other competitive examinations will develop the
            backbone of agriculture.
          </p>
          <p className="text-justify pt-3">
            We delineate the imagination of our students by organising
            competitions and events under the name Rural Agriculture Work
            Experience 2021 (RAWE) and we also offer a live experience by
            bringing people to the nearest village to become specialists in the
            field.Our college invites international agriculture specialists to
            give seminars online and offline, as well as specialists in all over
            India. Webinars and seminars are also offered to enrich the
            knowledge.
          </p>
          <div className="mt-5">
            <h4 className="text-success  my-3 fw-bolder">Our Vision</h4>
            <ul className=" ">
              <li>
                To make JSACAT a premier agriculture institute through quality
                education for human resource development.
              </li>
            </ul>
          </div>
          <div className="mt-5">
            <h4 className="text-success  my-3 fw-bolder">Our Mission</h4>
            <ul className=" ">
              <li className="text-justify">
                To provide exemplary learning environment and academic
                activities for transforming students into experts in
                agricultural technology, research process, transfer of
                technology and agri-preneurship : provide innovative solutions
                for short term and long-term issues in agriculture through
                research, extension and entrepreneurship.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-4">
          <a href="https://jsaagri.in" target="_blank" rel="noreferrer">
            <button className="btn btn-primary">Read More...</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default JSACAT;
