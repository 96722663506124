import React from "react";

import Logo from "../../../assets/images/logo/shens-logo.png";
const Shens = React.forwardRef((props, ref) => {
  return (
    <>
      <div className="row container align-items-center mx-0 mt-4" ref={ref}>
        <div className="col-md-4">
          <img src={Logo} alt="jsacat" width="100%" className="rounded" />
        </div>
        <div className="col-md-8 text-justify">
          <h4 className="text-success my-3 fw-bolder">
            Shens Multi-Speciality Hospital
          </h4>
          <p className="text-justify fw-bold">
            Shens Hospital has been providing splendid healthcare for the people
            in their diverse medical requirements.
          </p>
          <p className="text-justify">
            Over the past years until today, our astute leadership and strong
            management has evolved as a centre of excellence providing the
            highest quality standards for providing the best medical treatment
            to the entire society. We deliver our flawlessly combined
            revolutionary technology with the best medical expertise and
            advanced procedures..
          </p>
          <p className="text-justify">
            Our medical team comprises skilled professionals who excel in
            clinical and surgical skills with advanced procedures to benefit the
            patients by gaining minimal pain and discomfort, reduced waiting
            hours in the hospital, faster recovery period, quicker resumption of
            the regular routines in your life, and eradicating so many potential
            complications from conventional surgery.
          </p>
          <div className="row text-secondary">
            <h6 className="text-dark mb-3">Our Facilities</h6>
            <div className="col-sm-6">
              <ul>
                <li>Out patient Ward</li>
                <li>In patient Ward</li>
                <li>Echo</li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul>
                <li>Lab</li>
                <li>ECG</li>
                <li>ICU</li>
              </ul>
            </div>
          </div>
          <div className="my-2">
            <a href="https://shens.in/" target="_blank" rel="noreferrer">
              <button className="btn btn-primary">Read More...</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
});

export default Shens;
