import React from "react";

import BannerCarousel from "../../layouts/bannerCarousel";

const AboutUs = () => {
  return (
    <>
      <BannerCarousel BannerContent={null} />

      <div className="container">
        <h4 className="mt-5 fw-bolder text-header">
          About the <span className="">JSA Groups</span>
        </h4>
        <div className="row  align-items-center mt-5">
          <p className="text-justify">
            In 2009 <strong className="text-orange">JSA Groups</strong> started
            under the guidelines of{" "}
            <strong className="text-orange">
              Shri Venkateswara Trust as Charitable Educational Trust
            </strong>{" "}
            to uplift the educational levels of poor and to{" "}
            <strong className="text-success">
              empower women through education
            </strong>{" "}
            in socio - economically most backward district of TamilNadu.
          </p>
          <p className="text-justify">
            With this aim,{" "}
            <strong Jawaharlal className="text-orange">
              Jawaharlal Nehru Arts and Science College for Women
            </strong>{" "}
            was started in Ulundurpet, Kallakurichi district. Through the trust
            we also runs a{" "}
            <strong className="text-success">
              Free Treatment Siddha Hospital
            </strong>{" "}
            namely{" "}
            <strong className="text-orange">
              JSA Medical College for Siddha and Research Centre{" "}
            </strong>
            in Ulundurpet, Kallakurichi District.
          </p>
          <p className="text-justify">
            Our involvement in agriculture from childhood made us to realise the
            importance of agriculture.{" "}
            <strong className="text-success">
              Recognising the need for sustained agriculture through innovative
              production technologies
            </strong>
            , we aimed to inculcate the latest agricultural knowledge among the
            younger generation to bring them towards agriculture and to make
            them Agri-preneurs thereby retain them in the field. With this
            objective he started{" "}
            <strong className="text-orange">
              JSA College of Agriculture and Technology
            </strong>{" "}
            in the Most Backward region of Cuddalore District in TamilNadu from
            2014.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
