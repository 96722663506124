import Logo from "../../../assets/images/logo/LAK (1).png";
import React from "react";

const LakSkin = React.forwardRef((props, ref) => {
  return (
    <div>
      <div className="row container align-items-center mt-4 mx-0" ref={ref}>
        <div className="col-md-8 text-justify">
          <h4 className="text-success my-3 fw-bolder">
            Lak Skin Care Hospital
          </h4>
          <p className="text-justify">
            LAK SKIN CARE HOSPITAL, situated at Fair Lands, Salem, Tamil Nadu,
            with the aim to provide the best skin care. Our skin and hair
            treatments are carried on under the supervision of our trained
            doctors. Whether it is skin polishing or laser hair removal
            treatment, we make sure that our patients leave with a satisfactory
            result.
          </p>
          <p className="text-justify">
            At our clinic we provide consultation for all types of skin diseases
            and hair problems . The medicines that we prescribe are available at
            a cheap price. Visit our clinic for immaculate skin, hair treatments
          </p>
          <div className="row text-secondary">
            <h6 className="text-dark mb-3">Our Services</h6>
            <div className="col-sm-6">
              <ul>
                <li>Chemical Peels</li>
                <li>Cosmetic Treatment</li>
                <li>Treatment for Allergy and Exzema</li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul>
                <li>Hairloss Treatment</li>
                <li>Laser Hair Removal Treatment</li>
                <li>SkinCare Treatment</li>
              </ul>
            </div>
          </div>
          <div className="my-2">
            <a
              href="http://www.lakskinclinic.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-primary">Read More...</button>
            </a>
          </div>
        </div>
        <div className="col-md-4 ">
          <img
            src={Logo}
            alt="jsacat"
            width="70%"
            className="rounded d-block m-auto "
          />
        </div>
      </div>
    </div>
  );
});

export default LakSkin;
