import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";

const EnquiryForm = () => {
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState();
  const [eMail, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const formData = useRef();
  const fullNameHandler = (event) => {
    event.preventDefault();
    setFullName(event.target.value);
  };
  const mobileHandler = (event) => {
    event.preventDefault();
    setMobile(event.target.value);
  };

  const eMailHandler = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const messageHandler = (event) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const userDataHandler = async (event) => {
    event.preventDefault();
    const data = {
      Name: fullName,
      MobileNumber: mobile,
      Email: eMail,
      Message: message,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      await axios.post(
        "https://script.google.com/macros/s/AKfycbwZvmv-gCD9AlynVrwqrGn09Nzpl6ZKN1iR9zUaVzBZz1PWQHnZFZVPIOg0ztqHJxW8qw/exec",
        //sheet.best google sheep APi Service Link
        // "https://sheet.best/api/sheets/3d8f64c0-df5f-4af0-a232-d007b78cfc89",
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );

      alert(
        `Hello ${fullName}!!!\nYour form submitted successFully.\nOur Team will contact you soon...\nHave a Great Day.`
      );
      setFullName("");
      setMobile();
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  return (
    <>
      <form
        className="row g-3 needs-validation mt-4 "
        ref={formData}
        onSubmit={userDataHandler}
        data-aos="fade-in"
        data-aos-duration="2000"
        Validate
      >
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="validationCustom01" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                value={fullName}
                onInput={fullNameHandler}
                name="fullName"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationCustom01" className="form-label">
                Mobile Number
              </label>
              <input
                type="number"
                className="form-control"
                id="validationCustom01"
                value={mobile}
                onInput={mobileHandler}
                name="mobileNumber"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>

            <div className="col-md-6">
              <label htmlFor="validationCustom01" className="form-label">
                Email-Id
              </label>
              <input
                type="email"
                className="form-control"
                id="validationCustom01"
                value={eMail}
                onInput={eMailHandler}
                name="eMail"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="col-md-12">
              <label htmlFor="validationCustom01" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                aria-label="With textarea"
                value={message}
                onInput={messageHandler}
                name="message"
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <button className="btn btn-primary" type="submit">
            Submit form
          </button>
        </div>
      </form>
    </>
  );
};

export default EnquiryForm;
