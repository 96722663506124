import React from "react";
import JKPromotors from "./jkPromotors";
import KJPromotors from "./kjPromotors";
import Shens from "./shens";
import LakSkin from "./lakSkin";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const GroupOfCompanies = () => {
  const jkPromotors = useRef(null);
  const kjPromotors = useRef(null);
  const shensHospital = useRef(null);
  const lakSkinClinic = useRef(null);

  const { id } = useParams();

  const getRefByName = (refName) => {
    switch (refName) {
      case "jk-promotors":
        return jkPromotors;
      case "kj-promotors":
        return kjPromotors;
      case "shens-hospital":
        return shensHospital;
      case "lak-skin-clinic":
        return lakSkinClinic;
      default:
        return null;
    }
  };

  useEffect(() => {
    const ref = getRefByName(id);
    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [id]);

  return (
    <div className="container">
      <JKPromotors ref={jkPromotors} />
      <KJPromotors ref={kjPromotors} />
      <Shens ref={shensHospital} />
      <LakSkin ref={lakSkinClinic} />
    </div>
  );
};

export default GroupOfCompanies;
