import "./navBar.scss";
import Logo from "../../assets/images/logo/SVT-.png";
import Logo_JSA from "../../assets/images/logo/JSA_Groups_1.png";
import { useState } from "react";
import { useParams } from "react-router-dom";

const NavBar = () => {
  const [colorChange, setColorChange] = useState(false);
  const path = window.location.pathname;
  const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <nav
      className={
        colorChange
          ? "navbar navbar-expand-lg sticky-top   py-0 nav-active"
          : "navbar navbar-expand-lg  py-0"
      }
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={Logo_JSA} alt="logo" className="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-target="#navbarContent"
          data-bs-toggle="collapse"
          aria-controls="navContent"
          aria-expanded="false"
          aria-label="toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-lg-end justify-content-xs-center nav-links"
          id="navbarContent"
        >
          <ul className="navbar-nav gap-4 pe-4">
            <li className="nav-item">
              <a
                className={path === "/" ? "active nav-link" : "nav-link"}
                href="/"
              >
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <div
                className={
                  path.includes("/about-us") ||
                  path.includes("/leadership-team") ||
                  path.includes("/our-aim")
                    ? "active dropdown-toggle nav-link"
                    : "dropdown-toggle nav-link"
                }
                role="button"
                id="dropdownWho"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Who we are
              </div>
              <ul
                className="dropdown-menu border-0"
                aria-labelledby="dropdownWho"
              >
                <li>
                  <a className="dropdown-item" href="/about-us">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/leadership-team">
                    {" "}
                    Leadership Team
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="our-aim">
                    Our Aim
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <div
                className={
                  path.includes("group-of-companies")
                    ? "active dropdown-toggle nav-link"
                    : "dropdown-toggle nav-link"
                }
                id="dropdownInstitutions"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Group of Companies
              </div>

              <ul
                className="dropdown-menu border-0"
                aria-labelledby="dropdownInstitutions"
              >
                <li>
                  <a
                    className="dropdown-item "
                    href="/group-of-companies/jk-promotors"
                  >
                    JK Promotors
                  </a>
                  <li>
                    <a
                      className="dropdown-item "
                      href="/group-of-companies/kj-promotors"
                    >
                      KJ Promotors
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item "
                      href="/group-of-companies/shens-hospital"
                    >
                      Shens Hospital
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item "
                      href="/group-of-companies/lak-skin-clinic"
                    >
                      Lak Skin Clinic
                    </a>
                  </li>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <div
                className={
                  path.includes("group-of-institutions")
                    ? "active dropdown-toggle nav-link"
                    : "dropdown-toggle nav-link"
                }
                id="dropdownInstitutions"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Group of Institutions
              </div>

              <ul
                className="dropdown-menu border-0"
                aria-labelledby="dropdownInstitutions"
              >
                <li>
                  <a
                    className="dropdown-item "
                    href="/group-of-institutions/jsacat"
                  >
                    JSA College of Agriculture and <br />
                    Technology
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item "
                    href="/group-of-institutions/jsamcsrc"
                  >
                    JSA Medical College for Siddha and <br />
                    Research Centre
                  </a>
                </li>{" "}
                <li>
                  <a
                    className="dropdown-item "
                    href="/group-of-institutions/jncw"
                  >
                    Jawaharlal Nehru College for <br />
                    Women
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                className={
                  path === "/contact-us" ? "active nav-link" : "nav-link"
                }
                href="/contact-us"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
