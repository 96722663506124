import "./leaders.scss";
import Chairman from "../../../assets/images/leaders/chairman.jpg";
import Secretary from "../../../assets/images/leaders/secretary.jpg";
import Director from "../../../assets/images/leaders/director.jpg";
import Trustee from "../../../assets/images/leaders/trustee.jpg";

const Leaders = () => {
  return (
    <>
      <div className=" position-relative">
        <div className="leaders align-items-center d-flex justify-content-center">
          <h1 className="text-center text-light ">Our Team Leaders</h1>
        </div>
      </div>
      <div className="container">
        <div className="row  align-items-center mt-5">
          <h4 className="text-success text-center my-2 fw-bolder ">Chairman</h4>
          <div className="col-md-3">
            <img src={Chairman} alt="chairman" className="w-100 rounded" />
          </div>
          <div className="col-md-9 text-justify">
            <p className="text-primary mb-0">
              <span className="fw-bold fs-5">Dr. K. Jayaraman </span>
              <small className="fs-8 fw-bold"> BE., FIE, MBA, PhD.,</small>
            </p>
            <p className="text-success">
              Chairman & Managing Director, Shri Venkateswara Trust
            </p>
            <p>
              A renowned Philanthropist and Chief Engineer (Rtd.) Highways Dept.
              Govt. of Tamil Nadu., an exemplary leader with more than three
              decades of rich experience in the field of Engineering and
              Administration has been the driving force behind developing World
              Class Infrastructure Initiatives and Development in Tamil Nadu.
            </p>
            <p>
              With his interest in the field of education, culture and to
              motivate socio- economic and backward regions in the state, he
              started Shri Venkateswara Trust, a charitable and Non - Profit
              Organisation, aimed towards promoting quality education, women
              empowerment and to give free education to the poor in rural areas.
              Under his able guidance, Trust established Arts & Science and
              Agricultural College in Tamil Nadu. The trust also established a
              Medical college/Hospital in Siddha and serving the society by
              providing free treatment to the poor.
            </p>
          </div>
        </div>
        <div className="row container align-items-center mt-5">
          <h4 className="text-success text-center my-4 fw-bolder">Secretary</h4>
          <div className="col-md-8 text-justify">
            <p className="text-primary mb-0">
              <span className="fw-bold fs-5">Dr. Arun J.S.A</span>
              <small className="fs-8 fw-bold"> B.E., M.B.A, P.G.C.P.E,</small>
            </p>
            <p className="text-success">Secretary</p>
            <p>
              Completed Bachelor of Engineering (Civil) in 2012 at PSG College
              of Technology, Coimbatore, MBA in Mahatma Gandhi University,
              Meghalaya and Post Graduate Degree in Management, in International
              Management Institute, Brussels, Belgium.
              <br />
              <br />
              His vision is to ensure our young women graduates have the skills
              to succeed in their life and make a difference in the society. He
              always believes that modern society needs to bring sociological
              and education empowerment among women to address the growth and
              development of the nation. He ensured that all efforts of the
              college are focussed on providing the best facilities and
              opportunities to students, So that they are able to learn and grow
              in a congenial and rewarding environment
            </p>
          </div>
          <div className="col-md-4">
            <img src={Secretary} alt="secretary" className="w-100 rounded" />
          </div>
        </div>
        <div className="row container align-items-center">
          <h4 className="text-success text-center my-4 fw-bolder">
            Executive Director
          </h4>
          <div className="col-md-4">
            <img src={Director} alt="chairman" className="w-100 rounded" />
          </div>
          <div className="col-md-8 text-justify">
            <p>
              <p className="text-primary mb-0">
                <span className="fw-bold fs-5">Dr.Kanmani Arun </span>
                <small className="fs-8 fw-bold">M.B.B.S , DDVL (MMC) </small>
              </p>
              <p className="text-success">Executive Director</p>
              With a great vision in understanding that Education is an amazing
              place for women, she always aimed at Women's Inventory for success
              Empowerment. She helped the team create a unique blend of academic
              and practical learning modules and inculcate contemporary modern
              day skills among women from rural background. The customized
              modules are penetrating yet flexible and can be moulded to fit
              different needs of students. Under her able guidance, college has
              formulated an education that fills the gap between existing skill
              set of a student and the required skill set for corporate.
            </p>
            <p>
              In order to create a sustainable and a tremendous world, women
              play a key significant role. Women can capable enough to handle
              the entire task simultaneously with their full power and they can
              do multitasking work at a time. With so many social challenges
              along with financial challenges, women were forced to be in limit
              in the family as well as in society.
            </p>
          </div>
        </div>
        <div className="row  align-items-center mt-5">
          <h4 className="text-success text-center my-2 fw-bolder ">Trustee</h4>

          <div className="col-md-9 text-justify">
            <p className="text-primary mb-0">
              <span className="fw-bold fs-5">Ms. J. Santhi </span>
              <small className="fs-8 fw-bold"> MA.,</small>
            </p>
            <p className="text-success">Trustee</p>
            <p>
              "A Women can only understand a women's requirement". With a great
              vision in understanding that Education is an amazing place for
              women, she always aimed at Women's Inventory for Success
              Empowerment. She helped the team create a unique blend of academic
              and practical learning modules and inculcate contemporary modern
              day skills among women from rural background. The customized
              modules are penetrating yet flexible and can be moulded to fit
              different needs of students. Under her able guidance, College has
              formulated an education that fills the gap between existing skill
              set of a student and the required skill set for corporate
            </p>
          </div>
          <div className="col-md-3">
            <img src={Trustee} alt="Trustee" className="w-100 rounded" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaders;
