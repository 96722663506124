import React from "react";
import Banner_JSAMCSRC_jpg_1 from "../../../assets/images/banner/jsamcsrc/jpg/banner-1.jpg";
import Banner_JSAMCSRC_jpg_2 from "../../../assets/images/banner/jsamcsrc/jpg/banner-2.jpg";
import Banner_JSAMCSRC_jpg_3 from "../../../assets/images/banner/jsamcsrc/jpg/banner-3.jpg";
import Banner_JSAMCSRC_jpg_4 from "../../../assets/images/banner/jsamcsrc/jpg/banner-4.jpg";
import Banner_JSAMCSRC_jpg_5 from "../../../assets/images/banner/jsamcsrc/jpg/banner-5.jpg";
import Banner_JSAMCSRC_jpg_6 from "../../../assets/images/banner/jsamcsrc/jpg/banner-6.jpg";
import Banner_JSAMCSRC_jpg_7 from "../../../assets/images/banner/jsamcsrc/jpg/banner-7.jpg";
import Banner_JSAMCSRC_jpg_8 from "../../../assets/images/banner/jsamcsrc/jpg/banner-8.jpg";

import Banner_JSAMCSRC_webp_1 from "../../../assets/images/banner/jsamcsrc/webp/banner-1.webp";
import Banner_JSAMCSRC_webp_2 from "../../../assets/images/banner/jsamcsrc/webp/banner-2.webp";
import Banner_JSAMCSRC_webp_3 from "../../../assets/images/banner/jsamcsrc/webp/banner-3.webp";
import Banner_JSAMCSRC_webp_4 from "../../../assets/images/banner/jsamcsrc/webp/banner-4.webp";
import Banner_JSAMCSRC_webp_5 from "../../../assets/images/banner/jsamcsrc/webp/banner-5.webp";
import Banner_JSAMCSRC_webp_6 from "../../../assets/images/banner/jsamcsrc/webp/banner-6.webp";
import Banner_JSAMCSRC_webp_7 from "../../../assets/images/banner/jsamcsrc/webp/banner-7.webp";
import Banner_JSAMCSRC_webp_8 from "../../../assets/images/banner/jsamcsrc/webp/banner-8.webp";

import BannerCarousel from "../../layouts/bannerCarousel";

const JSAMCSRC = () => {
  const JSAMCSRCBanner = [
    {
      jpg: Banner_JSAMCSRC_jpg_1,
      webp: Banner_JSAMCSRC_webp_1,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_2,
      webp: Banner_JSAMCSRC_webp_2,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_3,
      webp: Banner_JSAMCSRC_webp_3,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_4,
      webp: Banner_JSAMCSRC_webp_4,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_5,
      webp: Banner_JSAMCSRC_webp_5,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_6,
      webp: Banner_JSAMCSRC_webp_6,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_7,
      webp: Banner_JSAMCSRC_webp_7,
    },
    {
      jpg: Banner_JSAMCSRC_jpg_8,
      webp: Banner_JSAMCSRC_webp_8,
    },
  ];
  return (
    <>
      <BannerCarousel BannerContent={JSAMCSRCBanner} />
      <div className="container">
        <h4 className="my-5 fw-bolder text-header">
          JSA Medical College College for Siddha and Research Center
        </h4>

        <div className="lh-lg">
          <p className="text-justify pt-3">
            JSA Medical College for Siddha and Research Centre is a world class
            institution, situated in 22 acres of land located at the National
            High way 45 (No.38, Trichy – Chennai High Way) at PALI, ULUNDURPET,
            with a builtup area of 2 lakh square feet. Our College Caters the
            needs of the rural population in and around major districts like
            Trichy,Salem,Thanjavur,Villupuram, Cuddalore & Pondicherry(UT). Our
            Institution is run by Shri Venkateswara Educational Trust which has
            been in the field of education for almost a decade. Our College is
            affiliated to The Tamilnadu Dr. M.G.R. Medical University, Chennai &
            Approved by Govt. of Tamil Nadu & Ministry of Ayush, Govt. of India.
          </p>
          <p className="text-justify  text-secondary fw-bold">
            AISHE Approval Code C-64516
          </p>

          <div className="mt-5">
            <h4 className="text-success  my-3 fw-bolder">Our Vision</h4>
            <ul className=" ">
              <li>
                To give world class education in Traditional SIDDHA Medicine
                originating in ancient Tamizhakam in South India
              </li>
              <li>To render dedicated service to mankind with humanity</li>
            </ul>
          </div>
          <div className="mt-5">
            <h4 className="text-success  my-3 fw-bolder">Our Mission</h4>
            <ul className="list-unstyled">
              <li className="ms-4">
                To be a premier Medical Institute in South India, offering
                professional education and training blended with ethical and
                human values, and to mould the Medical student into a dedicated
                doctor who will practice the art of medicine with high human
                value
              </li>
            </ul>
          </div>
        </div>
        <div className="my-4">
          <a href="https://jsasiddha.in" target="_blank" rel="noreferrer">
            <button className="btn btn-primary">Read More...</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default JSAMCSRC;
