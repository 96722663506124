import React from "react";
import Logo_JK from "../../../assets/images/logo/jkPromotors.png";
const JKPromotors = React.forwardRef((props, ref) => {
  return (
    <>
      <div className="row container align-items-center mx-0 mt-4" ref={ref}>
        <div className="col-md-4">
          <img src={Logo_JK} alt="jsacat" width="100%" className="rounded" />
        </div>
        <div className="col-md-8 text-justify">
          <h4 className="text-success  my-3 fw-bolder">JK Promotors</h4>
          <p className="text-justify">
            JK Promotors, a trusted name in the construction industry. With a
            commitment to excellence and a passion for building, we are
            dedicated to turning your vision into reality. As a leading
            construction company, we specialize in delivering high-quality
            projects tailored to meet the unique needs of our clients.
          </p>
          <p className="text-justify">
            At JK Promotors, we understand that construction is not just about
            bricks and mortar; it's about creating spaces that inspire,
            function, and endure. With years of experience in the industry, we
            have honed our skills and expertise to deliver exceptional results
            across a wide range of projects, from residential and commercial
            buildings to infrastructure and renovation projects.
          </p>
        </div>
      </div>
    </>
  );
});

export default JKPromotors;
