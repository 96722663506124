import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loader from "./components/loader";
import AboutUs from "./components/pages/aboutUs";
import Leaders from "./components/pages/leaders";
import ContactUs from "./components/pages/contactUs";
import OurAim from "./components/pages/ourAim";
import GroupOfCompanies from "./components/pages/groupOfCompanies";
import JSACAT from "./components/pages/groupOfInstitutions/jsacat";
import JSAMCSRC from "./components/pages/groupOfInstitutions/jsamcsrc";
import JNCW from "./components/pages/groupOfInstitutions/jncw";
const Routers = () => {
  const Home = lazy(() => import("./components/pages/home/index"));
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/leadership-team" element={<Leaders />} />
        <Route exact path="/our-aim" element={<OurAim />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route
          exact
          path="/group-of-companies/:id"
          element={<GroupOfCompanies />}
        />
        <Route
          exact
          path="/group-of-institutions/jsacat"
          element={<JSACAT />}
        />
        <Route
          exact
          path="/group-of-institutions/jsamcsrc"
          element={<JSAMCSRC />}
        />
        <Route exact path="/group-of-institutions/jncw" element={<JNCW />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
