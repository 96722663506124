import Image from "react-image-webp";
import "./bannerCarousel.scss";

import React from "react";
import Banner_JSAGROUPS_jpg_1 from "../../assets/images/banner/jsagroups/jpg/jsagroups.jpg";
import Banner_JSAGROUPS_jpg_2 from "../../assets/images/banner/jsagroups/jpg/banner-jsacat-1.jpg";
import Banner_JSAGROUPS_jpg_3 from "../../assets/images/banner/jsagroups/jpg/banner-jsacat-2.jpg";
import Banner_JSAGROUPS_jpg_4 from "../../assets/images/banner/jsagroups/jpg/banner-siddha-1.jpg";
import Banner_JSAGROUPS_jpg_5 from "../../assets/images/banner/jsagroups/jpg/banner-siddha-2.jpg";
import Banner_JSAGROUPS_jpg_6 from "../../assets/images/banner/jsagroups/jpg/banner-jncw-1.jpg";
import Banner_JSAGROUPS_jpg_7 from "../../assets/images/banner/jsagroups/jpg/banner-jncw-2.jpg";

import Banner_JSAGROUPS_webp_1 from "../../assets/images/banner/jsagroups/webp/jsagroups.webp";
import Banner_JSAGROUPS_webp_2 from "../../assets/images/banner/jsagroups/webp/banner-jsacat-1.webp";
import Banner_JSAGROUPS_webp_3 from "../../assets/images/banner/jsagroups/webp/banner-jsacat-2.webp";
import Banner_JSAGROUPS_webp_4 from "../../assets/images/banner/jsagroups/webp/banner-jsamcsrc-1.webp";
import Banner_JSAGROUPS_webp_5 from "../../assets/images/banner/jsagroups/webp/banner-jsamcsrc-2.webp";
import Banner_JSAGROUPS_webp_6 from "../../assets/images/banner/jsagroups/webp/banner-jncw-1.webp";
import Banner_JSAGROUPS_webp_7 from "../../assets/images/banner/jsagroups/webp/banner-jncw-2.webp";

const BannerCarousel = (props) => {
  const JSAGroupsBanner = [
    {
      jpg: Banner_JSAGROUPS_jpg_1,
      webp: Banner_JSAGROUPS_webp_1,
    },
    {
      jpg: Banner_JSAGROUPS_jpg_2,
      webp: Banner_JSAGROUPS_webp_2,
    },
    {
      jpg: Banner_JSAGROUPS_jpg_3,
      webp: Banner_JSAGROUPS_webp_3,
    },
    {
      jpg: Banner_JSAGROUPS_jpg_4,
      webp: Banner_JSAGROUPS_webp_4,
    },
    {
      jpg: Banner_JSAGROUPS_jpg_5,
      webp: Banner_JSAGROUPS_webp_5,
    },
    {
      jpg: Banner_JSAGROUPS_jpg_6,
      webp: Banner_JSAGROUPS_webp_6,
    },
    {
      jpg: Banner_JSAGROUPS_jpg_7,
      webp: Banner_JSAGROUPS_webp_7,
    },
  ];
  console.log(props.BannerContent);
  const bannerData = props.BannerContent ?? JSAGroupsBanner;
  return (
    <>
      <div
        id="banner_carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators ">
          {bannerData &&
            bannerData.map((item, index) => (
              <button
                type="button"
                data-bs-target="#banner_carousel"
                data-bs-slide-to={index}
                className={index === 0 && "active"}
                aria-label="indicator"
              ></button>
            ))}
        </div>
        <div className="carousel-inner">
          {bannerData &&
            bannerData.map((item, index) => (
              <div
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
              >
                <Image
                  className="d-block w-100 h-auto"
                  src={item.jpg}
                  webp={item.webp}
                  alt="carousel-banner"
                  // height="422"
                />
              </div>
            ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#banner_carousel"
          data-bs-slide="prev"
          aria-label="Control_btn"
        >
          <span className="carousel-control-prev-icon"></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#banner_carousel"
          data-bs-slide="next"
          aria-label="Control_btn"
        >
          <span className="carousel-control-next-icon"></span>
        </button>
      </div>
    </>
  );
};
export default BannerCarousel;
